import './App.css';
import Footer from './components/Footer';

function App() {
	return (
		<div className="justify-center">
			<div className="flex justify-center">
				<img
					src="/company_logo.png"
					alt="Retro Fusion Games Logo"
					width={480}
					height={137}
					priority
				/>
			</div>
			<div className="text-center tomorrow-bold mb-20">
				<p>
					WEBSITE UNDER DEVELOPMENT
				</p>
			</div>
			<Footer />
		</div>
	);
}

export default App;
