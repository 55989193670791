import FontAwesome from 'react-fontawesome';
import { Button } from "@material-tailwind/react";


function Footer() {
	return (
		<div className="flex justify-center">
			<Button type="button" onClick={(e) => window.location.href = 'mailto:hey@retrofusiongames.com'} className="tomorrow-regular text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2">
				<FontAwesome
					className="super-crazy-colors mx-1"
					name="envelope"
				/>
				hey@retrofusiongames.com
			</Button>
		</div>
	);
}

export default Footer;
